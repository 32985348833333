import React from 'react';

import useStore from '@/hooks/useStore';
import { openingHoursGlobal } from '@/lib/OpeningHours';

const Opening = () => {
  const { openingHours, siteConfig } = useStore('globals');
  const items = openingHoursGlobal(openingHours);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <li data-show-only="desktop">
        <span
          dangerouslySetInnerHTML={{
            __html:
              siteConfig?.siteGroup === 'servicing'
                ? items[0]
                : items.length > 3
                ? items.join('<br />')
                : items.join(' | '),
          }}
        />
      </li>
      <li data-hide-only="desktop">
        <span
          dangerouslySetInnerHTML={{ __html: siteConfig?.siteGroup === 'servicing' ? items[0] : items.join('<br />') }}
        />
      </li>
    </>
  );
};

export default Opening;
