type LinkField = LinkEntryFragment | LinkUrlFragment;

export function urlFor(link: LinkField | null | undefined) {
  if (!link) {
    return '';
  }

  if (link && 'entry' in link && link.entry?.[0]) {
    if (link.entry[0].uri === '__home__') {
      return '/';
    }
    return link.entry[0].uri ? `/${link.entry[0].uri}` : '';
  }

  if ('urlLink' in link && link.urlLink) {
    return link.urlLink;
  }

  return '';
}

// Get text for link, if no text, check link entry title or URL
export function textFor(link: LinkField | null | undefined) {
  if (!link) {
    return '';
  }

  if (link.text) return link.text;

  if (link && 'entry' in link && link.entry?.[0]) {
    return link.entry[0].title ? link.entry[0].title : '';
  }

  if ('urlLink' in link && link.urlLink) {
    return link.urlLink;
  }

  return '';
}

export function isExternal(link: LinkField | null | undefined) {
  if (!link) {
    return false;
  }

  if ('urlLink' in link && link.urlLink) {
    return /^http/.test(link.urlLink);
  }

  return false;
}

export function linkProps(link: LinkField | null | undefined) {
  return isExternal(link)
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};
}

export function isGenericLinkText(linkText: string) {
  return ['click here', 'click this', 'go', 'here', 'this', 'start', 'right here', 'more', 'learn more'].includes(
    linkText.toLowerCase(),
  );
}
