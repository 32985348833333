import React from 'react';

import useStore from '@/hooks/useStore';

import Fb from '@/images/icons/socials/facebook.svg';
import Instagram from '@/images/icons/socials/instagram.svg';
import Twitter from '@/images/icons/socials/twitter.svg';
import Youtube from '@/images/icons/socials/youtube.svg';

import styles from './styles.module.scss';

const Socials = () => {
  const { facebook, twitter, instagram, youtube } = useStore('globals');

  return (
    <>
      <div className={styles.socials}>
        {facebook && (
          <a href={facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <Fb />
          </a>
        )}
        {twitter && (
          <a href={twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <Twitter />
          </a>
        )}
        {instagram && (
          <a href={instagram} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <Instagram />
          </a>
        )}
        {youtube && (
          <a href={youtube} target="_blank" rel="noopener noreferrer" aria-label="Youtube">
            <Youtube />
          </a>
        )}
      </div>
    </>
  );
};

export default Socials;
