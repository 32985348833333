import { addDays, format, getMinutes, startOfWeek } from 'date-fns';

export function openingHoursGlobal(openingHours: OpeningTime[]) {
  const firstDOW = startOfWeek(new Date());
  const shortWeekDaysArray = Array.from(Array(7)).map((_, i) => format(addDays(firstDOW, i), 'ccc'));
  const firstDay = shortWeekDaysArray[0];
  shortWeekDaysArray.shift();
  shortWeekDaysArray.push(firstDay);

  const timeArr: string[] = openingHours.map((day: OpeningTime, i: number) => {
    if (day.open && day.close && shortWeekDaysArray[i]) {
      const open = new Date(day.open.date.replace(' ', 'T').replace('.000000', ''));
      const close = new Date(day.close.date.replace(' ', 'T').replace('.000000', ''));
      return `${format(open, open.getMinutes() ? 'h:mmaaa' : 'haaa')}-${format(
        close,
        close.getMinutes() ? 'h:mmaaa' : 'haaa',
      )}`;
    }

    return 'Closed';
  });

  if (timeArr.length === 0) {
    return [];
  }

  const sundayValue = timeArr[0];
  timeArr.shift();
  timeArr.push(sundayValue);

  const output: string[] = [];
  let d = 0;
  let t = '';
  timeArr.map((time: string, i: number) => {
    if (i === 0) {
      d = 0;
      t = time;
    } else if (time !== t) {
      if (i - d === 1) {
        output.push(`${shortWeekDaysArray[d]} ${timeArr[i - 1]}`);
        d = i;
        t = time;
      } else {
        const separated = i - d > 2 ? '-' : ' & ';
        output.push(`${shortWeekDaysArray[d]}${separated}${shortWeekDaysArray[i - 1]} ${timeArr[i - 1]}`);
        d = i;
        t = time;
      }
    }
  });

  if (d === 6) {
    output.push(`${shortWeekDaysArray[d]} ${timeArr[d]}`);
  } else {
    const separated = 6 - d === 1 ? ' & ' : '-';
    output.push(`${shortWeekDaysArray[d]}${separated}Sun ${timeArr[d]}`);
  }

  return output;
}

export function openingHoursTable(openingHours?: Nullable<OpeningTimeTableRow>[] | null) {
  const timeFormat = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;

  return openingHours
    ? openingHours
        .filter(
          (openingDay) =>
            openingDay &&
            openingDay.days &&
            openingDay.open &&
            openingDay.close &&
            timeFormat.test(openingDay.open) &&
            timeFormat.test(openingDay.close),
        )
        .map((openingDay) => {
          const regex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
          const missingHourRegex = /^([0-9]):([0-5][0-9])(:[0-5][0-9])?$/;
          if (openingDay!.days && openingDay?.open?.match(regex) && openingDay?.close?.match(regex)) {
            const openDate = openingDay?.open?.match(missingHourRegex)
              ? new Date(`2020-01-01T0${openingDay.open}`)
              : new Date(`2020-01-01T${openingDay.open}`);
            const closeDate = openingDay?.close?.match(missingHourRegex)
              ? new Date(`2020-01-01T0${openingDay.close}`)
              : new Date(`2020-01-01T${openingDay.close}`);

            return `${openingDay!.days}: ${
              getMinutes(openDate) ? format(openDate, 'h:mma').toLowerCase() : format(openDate, 'ha').toLowerCase()
            } - ${
              getMinutes(closeDate) ? format(closeDate, 'h:mma').toLowerCase() : format(closeDate, 'ha').toLowerCase()
            }`;
          }

          if (openingDay!.days) {
            return `${openingDay!.days}: Closed`;
          }

          return null;
        })
    : null;
}
