import React from 'react';
import cc from 'classcat';

import useStore from '@/hooks/useStore';

import FooterMenu from './FooterMenu';
import Socials from './Socials';

import styles from './styles.module.scss';

interface Props {
  withStickyBottom?: boolean;
}

const Footer = ({ withStickyBottom = false }: Props) => {
  const { siteConfig } = useStore('globals');
  const copyText = `© ${Footer.year} ${siteConfig?.siteGroup === 'servicing' ? 'IDOM PTY LTD.' : 'Autoleague'}`;

  return (
    <footer className={cc({ [styles.footer]: true, [styles.withStickyBottom]: withStickyBottom })}>
      <div className={styles.footerTop}>
        <div>
          <div>
            <FooterMenu />
          </div>
        </div>
      </div>
      <div className={styles.footerMiddle}>
        <div>
          <div>
            <Socials />
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div>
          <div>
            <span>
              {copyText}
              &nbsp;
              <a href="/privacy">Privacy Policy</a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.year = new Date().getFullYear();

export default Footer;
