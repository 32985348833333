import React from 'react';
import cc from 'classcat';

import MenuItem from './menuItem';

import styles from './styles.module.scss';

interface Props {
  parentItems: FooterLinkFragment[];
  links: FooterLinkFragment[];
}

const MenuColumn = ({ parentItems, links }: Props) => (
  <div className={styles.menuColumn}>
    {parentItems
      .filter((p): p is FooterLinkFragment => !!(p && '__typename' in p))
      .map(
        (p) =>
          p && (
            <ul key={p.title}>
              <li className={cc({ [styles.parent]: p.linkField?.length === 0 })}>
                <MenuItem item={p} />
              </li>
              {links
                .filter((c): c is FooterLinkFragment => !!(c && '__typename' in c && c.parent?.title === p.title))
                .map(
                  (c) =>
                    c && (
                      <li key={c.title} className={cc({ [styles.parent]: c.linkField?.length === 0 })}>
                        <MenuItem item={c} />
                      </li>
                    ),
                )}
            </ul>
          ),
      )}
  </div>
);

export default MenuColumn;
