import React, { useMemo } from 'react';
import cc from 'classcat';

import useSiteQuery from '@/hooks/useSiteQuery';
import useStore from '@/hooks/useStore';
import { IconMapPin, IconMessage, IconPhone } from '@/images/icons/tabler-icons';

import query from './footerMenu.graphql';
import MenuColumn from './menuColumn';
import Opening from './opening';

import styles from './styles.module.scss';

const FooterMenu = () => {
  const {
    general: { email, showContactInFooter, globalContactInfo, locationLinkField },
    siteConfig,
    isUsedCarPlatform,
    isBuyingSite,
    isFinanceSite,
    phone,
  } = useStore('globals');
  const [{ data }] = useSiteQuery<FooterLinksQueryVariables, FooterLinksQuery>({ query });
  const links = data?.footerLinks?.filter((p): p is FooterLinkFragment => !!(p && '__typename' in p)) || [];
  const parents = links.filter((item) => item && '__typename' in item && item.parent === null);
  const firstColumn = parents.filter((_, idx) => idx % 3 === 0);
  const secondMenu = parents.filter((_, idx) => idx % 3 === 1);
  const thirdMenu = parents.filter((_, idx) => idx % 3 === 2);

  const locationLinkProps = useMemo(() => {
    if (locationLinkField?.url && /^http/.test(locationLinkField?.url)) {
      return {
        target: '_blank',
        rel: 'noreferrer noopener',
      };
    }

    return {};
  }, [locationLinkField?.url]);

  const showAddressLocation = useMemo(() => {
    return isUsedCarPlatform && locationLinkField?.customText && locationLinkField.customText !== 'Location';
  }, [isUsedCarPlatform, locationLinkField?.customText]);

  const showInTouchLink = useMemo(() => {
    return isFinanceSite && locationLinkField?.customText && locationLinkField?.url;
  }, [isFinanceSite, locationLinkField?.customText, locationLinkField?.url]);

  return (
    <nav
      data-testid="footerMenu"
      aria-labelledby="footer-menu"
      className={cc({ [styles.navFooter]: true, [styles.navFooterMultiColumn]: parents.length > 3 })}
    >
      {firstColumn.length > 0 && <MenuColumn parentItems={firstColumn} links={links} />}
      {secondMenu.length > 0 && <MenuColumn parentItems={secondMenu} links={links} />}
      {thirdMenu.length > 0 && <MenuColumn parentItems={thirdMenu} links={links} />}
      <div>
        <ul>
          <li className={styles.parent}>
            <span>
              <strong>Help & support</strong>
            </span>
          </li>
          <Opening />
          {email && (
            <li>
              <span>
                Email us at:&nbsp;
                <a href={`mailto:${email}`}>{email}</a>
              </span>
            </li>
          )}
          {showAddressLocation ? (
            <>
              <li
                className={cc([
                  styles.locations,
                  siteConfig?.siteGroup === 'usedCarPlatform' && styles.usedCarPlatform,
                ])}
                data-testid="footerLocationLink"
              >
                <IconMapPin />
                <span>
                  <strong>Location</strong>
                </span>
              </li>
              <li className={styles.locationText}>
                {locationLinkField?.url ? (
                  <a href={locationLinkField.url} {...locationLinkProps}>
                    <span>{locationLinkField.customText}</span>
                  </a>
                ) : (
                  <span>{locationLinkField?.customText}</span>
                )}
              </li>
            </>
          ) : showInTouchLink ? (
            <li className={styles.locations}>
              <a href={locationLinkField?.url ?? '/locations'} {...locationLinkProps}>
                <IconPhone />
                {locationLinkField?.customText}
              </a>
            </li>
          ) : (
            siteConfig?.siteGroup !== 'servicing' &&
            locationLinkField?.url && (
              <li
                className={cc({
                  [styles.locations]: true,
                  [styles.nonMyMoto]: siteConfig?.siteGroup !== 'mymoto',
                })}
              >
                <a href={locationLinkField.url} {...locationLinkProps}>
                  <IconMapPin />
                  {isUsedCarPlatform || isBuyingSite ? 'Location' : 'Find a partner'}
                </a>
              </li>
            )
          )}
          {showContactInFooter && phone && (
            <li className={styles.locations}>
              <a href={`tel:${phone.replace(/[\D+]/g, '')}`}>
                <IconPhone />
                {phone}
              </a>
            </li>
          )}
          {globalContactInfo && (
            <>
              <li
                className={cc({
                  [styles.locations]: true,
                  [styles.nonMyMoto]: siteConfig?.siteGroup !== 'mymoto',
                })}
              >
                <IconMessage />
                <span>
                  <strong>Contact</strong>
                </span>
              </li>
              <li>
                <div className={styles.contactText} dangerouslySetInnerHTML={{ __html: globalContactInfo }} />
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default FooterMenu;
